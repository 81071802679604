{waitscreen} = require 'src/js/infrastructure'
{register} = require 'src/js/infrastructure/setup-registry'

{getModal} = require 'src/coffee/controls/modals'
﻿
setup = ($ctx) ->
    $ctx.find('button.open-transaction-history[data-modal-url]').on 'click', (event) ->
        promise = getModal $(event.currentTarget).data 'modal-url'

        waitscreen.waitFor promise

register('App.Dashboard.Setup', setup)
